import React from "react"
import Layout from "../components/Layout"
import { Content, Column, Image as GImage } from "rbx"
import Image from "gatsby-image"
import { Helmet } from "react-helmet"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Dan Lynch</title>
      <meta name="description" content="Dan Lynch | artist | inventor | entrepreneur" />
    </Helmet>
    <Content textAlign="center">
      <Column.Group>
        <Column>
          <Image fluid={data.fileName.childImageSharp.fluid} alt="Dan Lynch" />
        </Column>
        <Column>
          <h1>Design &amp; Technology</h1>
          <small>artist | inventor | entrepreneur</small>
        </Column>
      </Column.Group>
    </Content>
    <Content textAlign="left">
      <p>
        I’ve created companies and products that help people build and design
        web apps/sites that have been used by millions of users - with companies
        ranging from small businesses all the way up to Fortune 500 brands.
      </p>
      <p>
        I have a history that led me to a blend of sales, design, and
        engineering. At the age of 15 designing an Ann Arbor home in AutoCAD,
        then starting a successful sales company at 18, from Michigan I came to
        California with an innovative mindset where I worked in the VFX/CGI
        industry. All before receiving two degrees from UC Berkeley, I became a
        college instructor in computer graphics, and later ended up turning down
        a job at Apple to start two companies, famo.us and brandcast.com, both
        having grown to over 35 employees, and still operational with post
        series A funding of over $75M.
      </p>
      <p>
        During this time I learned how to not only build product and grow teams,
        but also create a business — at my last startup I brought in the first
        sale, and first 6-figure deal. As a direct result of my work in
        partnerships and sales, the platform I built with my team powered brands
        like WME/IMG, IDEO, New York Fashion Week, NFL + NRG, LPGA, and Miami
        Open. Having spent over a decade working on web platforms, I am
        determined to change the way people author content on the web.
      </p>
      <p>
        I think a lot about the future of the Internet and how technology
        effects all of us. Since I am one of the few who writes in the language
        that technology speaks, I feel responsible to use my digital scribe in a
        way that does good for humanity. And at the same time, I recognize the
        fact that as humans we sometimes just want to express ourselves: I
        believe that in today's world, code is the clay we can use to create
        beautiful digital sculptures.
      </p>
    </Content>
  </Layout>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "assets/dan-bio.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
